




import { Component, Vue } from "vue-property-decorator";
import ApplicationsTable from "@widgets/admin/Applications.vue";

@Component({
  components: { ApplicationsTable },
})
export default class Applications extends Vue {}
